<template>
    <div class="cashgrid">    
        <label class="leftjustified topmargin" for="">{{label}}</label>
        <button class="roll" v-show="rollLabel!='0'" @click="addRoll" tabindex="-1">{{rollLabel}}/Roll</button>
        <!-- placeholder -->
        <label class="roll" v-show="rollLabel=='0'"></label> 
        <input type="text" class="countText" v-model="count" @change="updateAmount" @focus="$event.target.select()">
        <label class="rightjustified topmargin" for="">X</label>
        <label class="rightjustified topmargin" for="">${{mathLabel}}</label>
        <label class="rightjustified topmargin" for="" >= $</label>
        <input type="text" class="amountText" v-model="amount" @change="updateTotal" tabindex="-1">

    </div>
</template>

<script>
export default {
    name:'cashentry',
    props:{
        label:{
            type:String,
            required:true,
            default:'Pennies'
        },
        
        rollLabel:{
            type:Number,
            required:true,
            default:50
        },
         mathLabel:{
            type:String,
            required:true,
            default:'0.01'
        },
    
    },
    data() {
        return {
            count:0,
            amount:0,
         
        };
    },
    
    computed:{
       

    },
    methods:{
      clear(){
        this.count=0
        this.amount=0
       
      },
      focus(){
        
      },
      addRoll(){
        this.count = Number(this.count) + Number(this.rollLabel)
        this.updateAmount()
      },
      updateAmount(){
        this.amount = (this.count * Number(this.mathLabel)).toFixed(2);
        this.updateTotal()
      },
      updateTotal(){
        this.$emit('updatecashtotal')
      }
    }
}
</script>

<style scoped>
.roll{
    width:62px;
}
.countText{
    width:60px;
}
.amountText{
    width:80px;
    margin-left: 5px;
}
.cashgrid{
    margin:5px;
    display:grid;
    grid-template-columns: 4fr 3fr 3fr 1fr 3fr 1fr 3fr;
}
.topmargin{
    margin-top:3px;
}

</style>