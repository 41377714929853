<template>
  <div :class="itemClass" @click="addItem">
    <label for class="item-item">{{ payItem.name }}</label>
    <label for class="item-net">{{ formattedprice }}</label>
    <removebutton  @click="remove" class="removebutton" v-if="okToRemove"/>
    <img src="@/assets/check.png"  class="iconimage" v-if="!okToRemove">
  </div>
</template>

<script>
import format from '../../format.js'
import removebutton from '../controls/removebutton.vue'
import {mapFields} from 'vuex-map-fields'
export default {
  name:'payitem',
  props: ["payItem"],
  computed: {
    ...mapFields([
            'payItems',
            
        ]),
    formattedprice(){
      return Number(this.payItem.price).toFixed(2)
    },
    itemClass: function() {
      return this.isSelected ? "item selecteditem" : "item";
    },
    okToRemove(){
      return true
      //return this.payItem.authorizationCode ==null
    }
  },
  mixins:[format],
  components:{
    removebutton
  },
  methods: {
    addItem(){
      this.$emit('additem',this.payItem.id)
    },
    remove(){
      this.$emit('removeitem',this.payItem.id)
    }
  },
  
};
</script>

<style lang="scss">

:root {
  --color-valid: rgb(128, 168, 128);
  --color-header-border: #13395d;
 

  --font-size-default: 14px;
  --font-size-biggest: 22px;
  --font-size-bigger: 18px;
  --font-color-default: black;
  --font-color-disabled: darkgray;


--background-color-disabled: lightgray;

  /* --background-color-default: white;
  --background-color-disabled: lightgray;
  --background-color-background: #c2b9b9;
  --background-color-inner: rgb(198, 205, 207);
  --background-color-inner-highlight: rgb(250, 246, 198);
  --background-color-button: #346278; /* #2c75aa; 
  --background-color-button-highlight: #8aa6b4; */

  --margin-default: 10px;
  --margin-bigger: 20px;
  --margin-smaller:5px;
  --margin-button: 10px 10px 20px 10px;

  --padding-default: 10px;
  --padding-mid:15px;
  --padding-bigger: 20px;
  --padding-smaller: 5px;

  --margin-none: 0px;

  --border-radius-default: 2px;
  --border-radius-less:0px;
  --border-radius-more:4px;
  --border-default: 1px solid #c2b9b9;
  --border-thick: 4px solid #c2b9b9;

  --box-shadow-default: 6px 6px 6px #6e6868;
  --box-shadow-smaller: 2px 2px 2px #6e6868;
}
.item {
  margin: var(--margin-smaller);
  border: var(--border-default);
  border-radius: var(--border-radius-default);
  padding: var(--padding-default);
  background-color: white;
  color:black;
  /* color:var(--font-color-light); */
  display: grid;
  grid-template-columns: 4fr 2fr 1fr;
  grid-template-rows: auto;
  min-width:100px;

}
.itemheader {
  font-weight: bold;
  margin: var(--margin-default);
}

.item-item {
  text-align: left;
}
.item-net {
  text-align:right;
}

.item-hr {
  grid-area: hr;
}
.buttons {
  grid-area: buttons;
}
.selecteditem {
  background-color: var(--background-color-inner);
}
.removebutton{
  margin-left:10px;
  margin-top:2px;
}
.iconimage{
  margin-left:65px;
}
</style>
