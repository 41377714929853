<template>
    <div class="pmtdiv">
        <smallbutton toplabel="Update Payments" @click="start()" v-if="showButton" :buttonclass="buttonClass"/>
        <linkbutton toplabel="Update Payments" @click="start()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodalwide ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">{{titletext}}</label>
                    <div class="payfull">
                        <div class="payitemavailable " > <!-- v-show="priorValid" -->
    
                            <addpayment buttonClass='btn cashpaymentbutton' name="Cash" :defaultAmount="due" @save="addPayItem" 
                                v-if="showCash" :disabled="disablePayments"/>
                            <addpayment buttonClass='btn paymentbutton' name="Check" :defaultAmount="due" @save="addPayItem" :isCheck="paramTrue" :numberDataLabel="checkNumberDataLabel" 
                                v-if="showCheck" :disabled="disablePayments"/>
                            
                            <addpayment buttonClass='btn paymentbutton' name="Card" :defaultAmount="due" @save="addPayItem" :numberDataLabel="cardNumberDataLabel" 
                                v-if="showBankcard" :disabled="disablePayments || integratedCards"/>
                            <addpayment buttonClass='btn paymentbutton' :name="accountBalanceLabel" :defaultAmount="due" @save="addPayItem" 
                                v-if="showAccount" :disabled="disablePayments || this.accountBalance==-1"/>
                            <addpayment_ar buttonClass='btn paymentbutton' :name="arLabel" :defaultAmount="due" @save="addPayItem_ar"   
                                v-if="showAR"  :subtypeList="arPaymentItemSubtypes" :disabled="disablePayments"/>
                            <addpayment buttonClass='btn paymentbutton' :name="faBalanceLabel" :defaultAmount="fadefaultpay" @save="addPayItem" 
                                v-if="showFA" :allowOverAvailableAmount="paramFalse" :availableAmount="faBalance" :disabled="disableFA || disablePayments"/>
                            <addpayment buttonClass='btn paymentbutton' name="Gift Certificate" :defaultAmount="due" @save="addPayItem" 
                                v-if="showGiftCertificate" :numberDataLabel="certNumberDataLabel" :disabled="disablePayments"/>
                            <addpayment_gift buttonClass="btn paymentbutton" name="Gift Card" :defaultAmount="due" @save="addPayItem" 
                                v-if="showGiftcard" :disabled="disablePayments"/>
                            
                            <!-- <addpayment buttonClass='btn paymentbutton' name="Other" :defaultAmount="due" @save="addPayItem"/> -->
                            <div class="allpmt">
                            <button class='btn paymentbutton' @click="addPayItemAccountAll" v-if="showAccountShortcut" :disabled="disablePayments">{{accountAllLabel}}</button>
                            </div>
                        </div>
                        <div class="payitemadded ">
                            <label class="eligiblemessage" v-if="cartEligibleForFA">*All items in Cart Eligible For Financial Aid*</label>
                            <label for class="text subtitle2">Payments</label>
                            <payitem v-for="payItem in payItems" v-bind:key="payItem.id" v-bind:payItem="payItem"  @removeitem="removeitem" />
                            <label for class="text text-right totaltext">Payment Total: {{ payTotal }}</label>
                            <label for :class="dueclass">Due: {{ due }}</label>
                           
                        
                        </div>

                    </div>
                    <div>
                        <label class="responsemessage">{{ responseMessage }}</label>
                    </div>
                    <div>
                        <smallbutton toplabel="Save" @click="saveticket" ></smallbutton>
                        <smallbutton toplabel="Cancel" @click="cancel"></smallbutton>
                    </div>
            </template>
          </actionmodalwide>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import datamethods from '../../services/datamethods.js'
    import actionmodalwide from '../controls/actionmodalwide.vue'
    import addpayment from '../actions/addpayment.vue'
    import addpayment_gift from '../actions/addpayment_gift.vue'
    import addpayment_ar  from '../actions/addpayment_ar.vue'
    import payitem from '../actions/payitem.vue'

    export default {
        name:'updatepayment',
        components:{
            smallbutton,
            linkbutton,
            actionmodalwide,
            payitem,
            addpayment,
            addpayment_gift,
            addpayment_ar
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:false
            },
            
            ticketId:{
                type:Number
            },
            buttonClass:{
                type:String,
                default:'button buttonprimary bottom10'
            }
            
        },
        mixins:[format, datamethods],
        data(){
            return{
                paramTrue:true,
                paramFalse:false,
                name:'Detail',
                trueParam:true, 
                ticket:null,
                payTotal:0.00,
                due:0.00,
                payItems:[],
                payItemCount:0,
                arPaymentItemSubtypes:[],
                guestAccounts:[],
                faBalance:0,
                hasIntegratedCardPayment:false,
                hasFinancialAidPayment:false,
                cartEligibleForFA:false,
                inSave:false,
                isDirty:false

            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait',
                'location',
                'terminalId',
             
     
            ]),
            
            disableFA(){
                return !this.cartEligibleForFA  || this.faBalance<=0
            },
            disablePayments(){
                var single = false
                if(window.SINGLE_PAYMENT!=undefined){single=window.SINGLE_PAYMENT}
                return single && this.payItems.length>0
            },
            titletext(){
                return "Update Payment for " + this.ticket.guestName + ' $' + this.ticket.total
            },
            dueclass(){
                if(this.due==0){return "text text-right totaltext paycolor"}
                else{return "text text-right totaltext redtext"}
            },
            accountBalance(){
                if(this.guestAccounts==undefined){ return -1; }
                if(this.guestAccounts.length==0){return -1}
                return this.guestAccounts[0].currentBalance

            },
                
            accountBalanceLabel(){
                var acctlabel="Account"
                if(window.ACCOUNT_LABEL!=undefined){acctlabel=window.ACCOUNT_LABEL}
                var bal = this.accountBalance;
                if(bal==-1){
                    return "No Account Available"
                }else{
                    return acctlabel + ": " + this.$options.filters.toCurrency(bal)
                }
                
            },
            arLabel(){
                var lbl = "AR"
                if(this.ARPaymentItemSubtypeName!=null){
                    lbl = this.ARPaymentItemSubtypeName
                }
                return lbl
            },
            faBalanceLabel(){
                var acctlabel="Financial Aid"
                var bal = this.faBalance;
                if(bal==-1){
                    return "No Financial Aid Available"
                }else{
                    return acctlabel + ": " + this.$options.filters.toCurrency(bal)
                }
            },
            accountAllLabel(){
                var acctlabel="Account"
                if(window.ACCOUNT_LABEL!=undefined){acctlabel=window.ACCOUNT_LABEL}
                return "Pay All Now with " + acctlabel 
            },
            AutoStartOver(){
                if(window.AUTO_START_OVER==undefined){return false}
                else{return window.AUTO_START_OVER}
            },
            integratedCards(){
                
                if(window.INTEGRATED_CARDS==undefined){return false}
                else{return window.INTEGRATED_CARDS}
            },
            showCheck(){
                
                if(window.SHOW_CHECK==undefined){return false}
                else{return window.SHOW_CHECK}
            },
            showAR(){
                
                if(window.SHOW_AR==undefined){return false}
                else{return window.SHOW_AR}
            },
            showCash(){
                if(window.SHOW_CASH==undefined){return false}
                else{return window.SHOW_CASH}
            },
            showBankcard(){
                if(window.SHOW_BANKCARD==undefined){return false}
                else{return window.SHOW_BANKCARD }
            },
            showGiftcard(){
                if(window.SHOW_GIFTCARD==undefined){return false}
                else{return window.SHOW_GIFTCARD}
            },
            showGiftCertificate(){
                if(window.SHOW_GIFTCERTIFICATE==undefined){return false}
                else{return window.SHOW_GIFTCERTIFICATE}
            },
            showAccount(){
                if(window.SHOW_ACCOUNT==undefined){return false}
                else{return window.SHOW_ACCOUNT}
            },
            showFA(){
                if(window.SHOW_FINANCIALAID==undefined){return false}
                else{return window.SHOW_FINANCIALAID}
            },
            cardNumberDataLabel(){
                if(this.integratedCards){ return ""}
                else {return "Last 4 Digits"}
            },
            checkNumberDataLabel(){

                return "Check #"
            },
            certNumberDataLabel(){
                return 'Certificate #'
            },
            showAccountShortcut(){
                if(window.SHOW_ACCOUNT_SHORTCUT==undefined){return false}
                else return window.SHOW_ACCOUNT_SHORTCUT
            },
        },
        methods:{
            cancel(){
                this.responseMessage=null
                this.$refs.mymodal.closeModal()
            },
            amount(pi){
                var amt = pi.amount
                if(pi.changeGiven>0){
                     amt = pi.amount - pi.changeGiven
                }
                return this.$options.filters.toCurrency(amt)
            },
            toDate(dt){
                return this.$options.filters.formatTime(dt);
            },
           
            
            ticketclass(g){
                let c = "guestdetails panel-inner"
                if(g.id==this.ticketId){
                    c=c+" color-highlight"
                }else{c=c+" color-inner"}
                return c
            },
            selectTicket(id){
                console.log('sel ' + id)
                this.ticketId=id
            },
            start(){
                this.responseMessage=null
                this.isDirty=false
                this.refreshticket()

                
            },
            async refreshticket(){
                let req = {
                    ticket:{
                        id:this.ticketId
                    }
                }  
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'ticket')
                //handle response
            
                if(response.Successful){
                    this.ticket=response.ticket

                    var i
                    this.payItems=[]
                    this.hasIntegratedCardPayment=false
                    this.hasFinancialAidPayment=false
                    this.payItemCount= this.ticket.paymentItems.length - 1
                    for (i = 0; i <= this.ticket.paymentItems.length - 1; i++) {
                        let newitem={
                            id:this.ticket.paymentItems[i].id,
                            name:this.ticket.paymentItems[i].typeName,
                            numberData:this.ticket.paymentItems[i].numberData,
                            price:this.ticket.paymentItems[i].amount,
                            authorizationCode:this.ticket.paymentItems[i].authorizationCode
                        }
                        this.payItems.push(newitem) 
                        if(this.ticket.paymentItems[i].typeName=='card' && this.integratedCards){this.hasIntegratedCardPayment=true}
                        if(this.ticket.paymentItems[i].typeName=='financial aid'){this.hasFinancialAidPayment=true}
                    }
                
                    var eligible = true;
                    for (i = 0; i <= this.ticket.lineItems.length - 1; i++) {
                        if (!this.ticket.lineItems[i].isFinancialAidEligible) {
                            eligible=false
                        }
                    }
                    this.cartEligibleForFA=eligible

                    //if int card or fa, message
                    if(this.hasFinancialAidPayment){
                        this.responseMessage="You may not update the payments on a ticket paid with Financial Aid. You will need to void the ticket and start a new ticket through the POS."
                        return  
                    }
                    if(this.hasIntegratedCardPayment){
                        this.responseMessage="You may not update the payments on a ticket paid with a Credit Card. You need to start a new Return Ticket through the POS."
                        return  
                    }
                    
                    this.$refs.mymodal.openModal()
                    
                    this.updateTotals()
                    if(this.showAR){this.getARPaymentSubTypeByGuest()}
                    if(this.showAccount){this.GetGuestAccount()}
                    if(this.showFA){this.GetGuestFinancialAid()}
                }else{
                this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            async saveticket(){
                if(this.inSave){return}

                //if we didn't change anything, don't save
                if(!this.isDirty){
                    this.inSave=false
                    this.$refs.mymodal.closeModal()
                    return
                }
                
                if(this.due!=0){
                    console.log('due!!')
                    this.responseMessage="Please enter payments that add up to exactly $" + this.ticket.total
                    return 
                }
                this.inSave=true
                //make new pay items back into ticket line items
                var newItems=[]
                var i
                for (i = 0; i <= this.payItems.length - 1; i++) {
                        let tpi={
                            ticketPaymentId:this.ticket.ticketPaymentId,
                            ticketId:this.ticket.id,
                            typeName:this.payItems[i].name,
                            terminalId:this.ticket.terminalId,
                            businessDayId:this.ticket.businessDayId,
                            guestId:this.ticket.guestId,
                            amount:this.payItems[i].price,
                            numberData:this.payItems[i].numberData,
                            guestAccountBalance:this.accountBalance,
                            changeGiven:this.payItems[i].changeGiven,
                            subTypeId:this.payItems[i].subTypeId

                        }
                        
                        newItems.push(tpi) 
                    }

                let req = {
                    ticket:this.ticket,
                    payItems:newItems
                }  
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'updateticketpayments')
                //handle response
                this.inSave=false
                if(response.Successful){
                    
                    this.$emit('refresh')
                    this.$refs.mymodal.closeModal()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
                this.inSave=false
            },


            removeitem(ItemID){
                var i;
                for (i = 0; i <= this.payItems.length - 1; i++) {
                    if (this.payItems[i].id == ItemID) {
                        //this.removeItemFromData(this.payItems[i])
                        this.payItems.splice(i,1)
                        break; //only take this one
                    }
                }
                this.isDirty=true
                this.updateTotals();
                   
            },
            addPayItem(type,amt,num,change){
                //var autoreceipt = this.autoPrintReceiptOnSave(type)
                var newItem;
                //add anew one   
                newItem = {
                    id:1,
                    name:type,
                    price:amt,
                    numberData:num,
                    changeGiven:change,
                    authorizationCode:null
                
                }
                this.payItems.push(newItem);
                this.isDirty=true
                this.updateTotals()
                //this.addItemToData(newItem,autoreceipt)
                    
            },   
            addPayItem_ar(type, amt, subtypeid){
               // var autoreceipt = this.autoPrintReceiptOnSave(type)
                var newItem;
                //add anew one   
                newItem = {
                    id:1,
                    name:type,
                    price:amt,
                    authorizationCode:null,
                    subTypeId:subtypeid
                }
                this.payItems.push(newItem);
                this.updateTotals()
                
                    //this.addItemToData(newItem,autoreceipt)
            },
            updateTotals(autoreceipt=false){
                this.responseMessage=null
                var i;
                var total = 0;
                this.haveUnprocessedCard=false;

                for (i = 0; i <= this.payItems.length - 1; i++) {
                    console.log('total ' + total)
                    console.log('price ' + this.payItems[i].price + ' ' + Number(this.payItems[i].price))
                    total = total + Number(this.payItems[i].price);
                    console.log('total ' + total)
                    //console.log(this.payItems[i].name + ' ' + this.payItems[i].authorizationCode)
                    if(this.payItems[i].name=='Cash'){
                        this.isCash=true
                    }
                    if(this.payItems[i].name.toLowerCase()=='card' && this.payItems[i].authorizationCode==null){
                        this.haveUnprocessedCard=true
                        console.log(this.payItems[i].name + ' ' + this.payItems[i].authorizationCode)
                        console.log(this.haveUnprocessedCard)
                    }
                }
                console.log('add up pmts ' + this.total)
                this.payTotal = total.toFixed(2);
                this.due = (this.ticket.total-this.payTotal).toFixed(2);

                /* if(this.due<0 && this.isCash==true){
                    this.change=this.due * -1;
                    this.due=0
                } */
                //console.log('after change: ' + this.due +  ' ' + this.change)

                var amt = this.$options.filters.toCurrency(this.ticket.total);
         
                if(this.due==0){
                    
                }
                //this.$emit('refreshticket')
            },
            async getARPaymentSubTypeByGuest(){
                //clear
                this.arPaymentItemSubtypes = []
            
                //this.ticket.guestId
                let req = {
                    ID:-1,
                }  
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'subtypebyguest')
                //handle response
            
                if(response.Successful){
                    this.arPaymentItemSubtypes = response.ValueList
                    //this.ARPaymentItemSubtypeId = response.LocationPOS.ticketPaymentItemSubtypeId_AR
                    //this.ARPaymentItemSubtypeName = response.LocationPOS.ticketPaymentItemSubtypeName
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            async GetGuestAccount(createticket=true){
                let req = {
                    personId:this.ticket.guestId,
                    locationId:this.ticket.locationId
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'AccountByGuest')
                //handle response
            
                if(response.Successful){
                    this.guestAccounts = response.AccountList
                

                
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            async GetGuestFinancialAid(){
                //only if we are showing fa
                if(window.SHOW_FINANCIALAID==false){return}

                //this.panels[0].subtitle2="Looking up Financial Aid..."

                let req = {
                    personId:this.ticket.guestId,
                    locationId:this.ticket.locationId
                }
                //call api
                this.$store.dispatch('beforeAPI');
                
                var response = await this.callAPI(req,'financialaidbyguest')
                //handle response
                
                if(response.Successful){
                    this.faBalance=Number(response.StringResponse)
                
                if(this.faBalance<=0){
                   // this.panels[0].subtitle2="NO Financial Aid Available"
                    this.faBalance=0
                }else{
                    //this.panels[0].subtitle2="Financial Aid: $" + this.faBalance.toFixed(2)
                }
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
        }
    }
    
    </script>
    
    <style>
    .ticketlineitem{
        display:grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
    .paymentitem{
        display:grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
    .ticketfield{
        min-width:100px;
    }
    .ticketrecord{
        display:grid;
        grid-template-columns: 1fr 1fr 4fr 1fr 1fr 2fr 2fr;
    }
    .leftjustify{
        text-align: left;
    }
    .rightjustify{
        text-align: right;
    }
    .payfull {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 80px;
  grid-template-areas:
    "payavailable payadded"
    "open next";
  padding: 20px;
}
    .payitemavailable {
  grid-area: payavailable;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height:100px;
}
.payitemadded {
  grid-area: payadded;
  display: flex;
  flex-direction: column;
  border-left: 1px solid gray;
  padding-left:20px;
  margin-left:10px;
  text-align:right;
  align-items: right;
}
.btn {
  background-color: var(--button-color);
  color: var(--light-text-color);
  margin: var(--margin-default);
  border: none;
  font-size: inherit;
  border-radius: var(--border-radius-default);
  padding: var(--padding-bigger);
  
}
.paymentbutton {
  background-color: var(--color-payment);
  color: var(--light-text-color);
  min-width:120px;
}
.cashpaymentbutton{
  background-color: var(--color-cash);
  color: var(--light-text-color);
  min-width:120px;
}
.subtitle2 {
  font-size: 20px;
  
}
.text-right {
  text-align: right;
  margin:5px;
  
}
.text{
    margin:5px;
}
.totaltext{
  font-size:larger;
  
}
.paycolor{
  color:var(--color-payment);
  font-weight:bold;
}
.redtext{
  color:red;
  font-weight:bold;
}
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    