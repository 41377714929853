<template>
    <div>

        <!-- MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">{{title}}</label>
                <label >{{body}}</label>
                <div>
                    <smallbutton  @click="clickedok" toplabel="OK"/>
                </div>
                <div>
                  <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import smallbutton from '../controls/button.vue'
    import actionmodal from './actionmodal.vue'
    
    export default {
        name:'messagebox',
        components:{
            smallbutton,
            actionmodal,

            
        },
        props:{
            title:{
                type:String,
                required:true
            },
            body:{
                type:String,
            },

            disabled:{
              type:Boolean,
              default:false
            },
            buttonclass:{
                type:String,
                default:"button buttonprimary"
            }
        },
        data(){
            return{
                responseMessage:''
            }
        },
        methods:{
            Show(){
                this.$refs.mymodal.openModal()
            },
            clickedok(){
                this.$emit('ok')
                this.$refs.mymodal.closeModal()
            }
        }
    }
    </script>
    
    <style>
    
    </style>