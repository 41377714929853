<template>
    <div>
        <smallbutton v-if="showButton" :toplabel="title" @click="startAdd()"  :buttonclass="buttonclass"/>
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form >
                    <label class="titlelabel">{{ title }}</label>
                    <br/>
                    <br>
                    <div class="onhandheader">
                        <p class="">Code</p>
                        <p class="">Name</p>
                        <p class="">On Hand</p>
                        <p class="">{{ newQtyText }}</p>
                    </div>
                    <div v-for="(i,index) in inventoryItemList" :key="i.id" :value="i.id" class="onhandrecord">
                        <input class="onhandinput" v-model="i.code" :ref="'field-'+index" v-on:keydown.tab="lookup(i)" v-on:keyup.enter="lookup(i)"/>
                        <p class="">{{i.name}}</p>
                        <p class="">{{i.onHandQty}}</p>
                        <input class="onhandinput" v-model="i.newQty" :ref="'onhand-'+index" v-on:keyup.enter="addRow" v-on:keydown.tab.prevent="addRow" :disabled="i.name==null"/>
                    </div>
                    <div class="horizFlexStretch">
                        <div class="littlelabel">
                            <label class="littlelabel">Use Tab to go to the next field</label>
                        </div>
                        <smallbutton @click="addRow" toplabel="Add Row" buttonclass="buttonprimary smallbtn" />
                    </div>
                    <div class="urlclass" v-if="reportURL!=null">
                        <a :href="reportURL" target="_blank" ref="inventoryreportlink">Click to view or print the Product Inventory Action Set Report </a>
                    </div>
                   
     
                        <div class="horizFlexCenter">
                            <confirm :showButton="paramTrue" body="Do you want to print an inventory action report?" title="Save" @yes="save(true)" @no="save(false)" buttonclass=" buttonprimary button"/>
                            <!-- <smallbutton  @click="save()" toplabel="Save" /> -->
                            <smallbutton  @click="close()" toplabel="Close"/>
                        </div>
                  
                    
                    
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    
                </form>

            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../services/datamethods.js'
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import datamethodsreport from '../../services/datamethods-report.js'
     import confirm from '../actions/confirm.vue'
    export default {
        name:'onhand',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            confirm

        },
        props:{
            

            buttonclass:{
              type:String,
              default:"button buttonprimary"
            },
            setonhand:{
                type:Boolean,
                default:false
            },
            consume:{
                type:Boolean,
                default:false
            },
            receive:{
                type:Boolean,
                default:false
            },
            showButton:{
                type:Boolean,
                default:false
            }
         
        },
        mixins:[datamethods,datamethodsreport,format],
        data(){
            return{
                
                responseMessage:'',
                inventoryItemList:[],
            
                reportURL:null,
                pleaseWait:false,
                paramTrue:true
            }
        },
        computed:{
            
            ...mapFields([
             
             'loggedInUser',
             'inventoryFilter',
             'reportImageBasePath'
    
            ]),
            title(){
                if(this.setonhand){
                    return "Set On Hand"
                }else if(this.consume){
                    return "Consume Stock"
                }else if(this.receive){
                    return "Receive Stock"
                }
            },  
            newQtyText(){
                
                if(this.setonhand){
                    return "NEW On Hand"
                }else if(this.consume){
                    return "Adjust By"
                }else if(this.receive){
                    return "Adjust By"
                }
            },
            
      
        },
        mounted(){
           
        },
        methods:{
           close(){
            this.$emit('refresh')
            this.$refs.mymodal.closeModal()
           },
            beforeAPI(){
                this.pleaseWait=true
                this.responseMessage=''
            },
            
          
            startAdd(){
                this.inventoryItemList=[]
                this.reportURL=null
                this.$refs.mymodal.openModal()
                this.addRow()
            },
            
            async save(print=false){
                let req = {
                  inventoryItemList:this.inventoryItemList,
                  isSetOnHand:this.setonhand,
                  isConsume:this.consume,
                  isReceive:this.receive,
                  personId:this.loggedInUser.user_personId,
                  locationId:this.inventoryFilter.locationId
              }
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI(req,'actionset')
              //handle response
              if(response.Successful){
                this.responseMessage = this.title + " Saved Successfully"
                this.printReport(response.ResponseInteger,print)
               // this.$refs.mymodal.closeModal()
              }else{
                  this.responseMessage = response.Message
              }
              this.pleaseWait=false;  
        
            },
            async printReport(id, print=false){
                let req = {
                  Report:{Name:'ProductInventoryActionSetReport',
                    Parameters:[{
                        SPParamName:'@ProductInventoryActionsetID',
                        CurrentValue:id
                    }]
                  }
              }
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI_Report(req,'generate')
              //handle response
              if(response.Successful){
                if(response.Instances==null){
                    console.log('no instance')
                    return;
                }

                
                this.reportURL = this.reportImageBasePath + response.Instances[0].ExportPath
                if(print){
                    this.$nextTick(() => {
                        this.$refs.inventoryreportlink.click()
                    })
                }
                //this.$refs.mymodal.closeModal()
              }else{
                  this.responseMessage = response.Message
              }
              this.pleaseWait=false;  
            },
            newRow(){
                var row={
                    id:null,
                    name:null,
                    code:null,
                    onHandQty:0,
                    inventoryId:null,
                    newQty:null
                }
         
                return row
            },
            checkkey(e){
                console.log(e)
                if(e.key=='tab' || e.key=='enter'){
                    this.lookup(i)
                }
            },
            async lookup(i){
                this.responseMessage=null
                let req = {
                  InventoryFilter:{
                    locationId:this.inventoryFilter.locationId,
                    typeId:-1,
                    lineId:-1,
                    vendorId:-1,
                    filterString:i.code
                  }
              }
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI(req,'inventory')
              //handle response
              if(response.Successful){
                    if(response.InventoryItemList.count==0){
                        this.responseMessage="Not found"
                        return
                    }
                    let lookupitem = response.InventoryItemList[0]
                    if(lookupitem==undefined){
                        this.responseMessage="Not found"
                        return
                    }
                   i.name= lookupitem.name
                   i.onHandQty=lookupitem.onHandQty
                   i.id=lookupitem.id
                   i.inventoryId=lookupitem.inventoryId
                   this.$nextTick(() => {
                    this.$refs["onhand-"+(this.inventoryItemList.length-1)][0].focus()
                    })
              }else{
                  this.responseMessage = response.Message
              }
              this.pleaseWait=false;  
            },
            addRow(){
                var row = this.newRow()

                this.inventoryItemList.push(row)
               
               
                this.$nextTick(() => {
                    this.$refs["field-"+(this.inventoryItemList.length-1)][0].focus()
                })
            } ,
                
        },
        mounted(){
            
            var override =''
            if(override=='' && window.CONFIG_OVERRIDE!=undefined){
                override = window.CONFIG_OVERRIDE
            }

            this.AddConfigToAPI(override);
        }
    }
    </script>
    
    <style>
    .onhandrecord{
        text-align:left;
        vertical-align:middle;
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding:10px 0px 0px 10px;
        border:1px solid gray;
    }
    .onhandheader{
        text-align:left;
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding:10px 0px 0px 10px;
    }
    .onhandinput{
        width:60px;
        height:25px;
        
        
    }
    .urlclass{
        margin:20px;
    }
    .smallbtn{
    border: none;
border-radius:5px;
width:75px;
height:30px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
color:var(--light-text-color);
 }
 .littlelabel{
    margin-top:10px;
 }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    