import { createApp } from 'vue'
import App from './App.vue'

import './registerServiceWorker'
import {store} from './store/store'
//createApp(App).mount('#app')

const app = createApp(App)
app.use(store)

app.mount('#app')