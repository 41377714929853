<template>
<div class="pmtdiv">
    <smallbutton :toplabel="name" @click="startAdd()" v-if="showButton" :buttonclass="buttonClass" :disabled="disabled"/>
    <linkbutton :toplabel="name" @click="startAdd()" v-if="!showButton"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="balform">
                <label class="titlelabel">{{name}}</label>
                <br/>
                <div class="entry">    
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Amount $</label>
                        <label class="required">*</label>
                    </div>
                    <input  class="entryText capital" type="currency"  name="" ref="amtfield" required="true" v-model="amount"
                        @focus="$event.target.select()" >
                    
                </div>
                <!-- <formentry  isrequired="True" label="Amount $"  v-model="amount" ref="amtfield" /> -->
                <div v-if="name=='Cash'" class="addbtndiv">
                    <smallbutton  @click="add(1)" toplabel="$1" buttonclass="addbtn"/>
                    <smallbutton  @click="add(5)" toplabel="$5" buttonclass="addbtn"/>
                    <smallbutton  @click="add(10)" toplabel="$10" buttonclass="addbtn"/>
                    <smallbutton  @click="add(20)" toplabel="$20" buttonclass="addbtn"/>
                </div>
                <div v-if="numberDataLabel!=''">
                    <formentry   :label="numberDataLabel"  v-model="numberData" isrequired="True" />
                </div>

                
               <!--  <div>
                    <label for="" class="required">*Required</label>
                </div> -->
                <smallbutton  @click="Save()" toplabel="Save"/>
                
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
                
            </form>
            
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodal from '../controls/actionmodal.vue'
import formentry from '../controls/entry.vue'

export default {
    name:'addpayment',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton
    },
    props:{
       
        showButton:{
            type:Boolean,
            default:true
        },
        name:{
            type:String,
            default:'Add Payment'
        },
        defaultAmount:{
            type:Number,
        },
        numberDataLabel:{
            type:String,
            default:''
        },
        buttonClass:{
            type:String,
            default:'button buttonpay'
        },
        allowOverAvailableAmount:{
            type:Boolean,
            default:true
        },
        availableAmount:{
            type:Number,
            default:0
        },
        disabled:{
            type:Boolean,
            default:false
        }
        
    },
    mixins:[format],
    data(){
        return{
            
            amount:0,
            numberData:null,
            inSave:false,
            responseMessage:null
            
        }
    },
 
    computed:{
        ...mapFields([

            'pleaseWait'
        ]),
        singlePay(){
            if(window.SINGLE_PAYMENT==undefined){return false}
            else{return window.SINGLE_PAYMENT}
        }
    },
    methods:{
        add(amt){
            if(this.textIsSelected()){
                this.amount = amt
            }else{
                this.amount = Number(this.amount) + Number(amt)
            }
            
        },
        startAdd(){
            this.responseMessage=null
            this.numberData=null
            this.inSave=false

            //if cash, emit drawer
            if(this.name=='Cash'){this.$emit('opendrawer')}
            
            //if(Number(this.defaultAmount) > Number(this.availableAmount)){ this.defaultAmount=Number(this.availableAmount)}

            this.amount = Number(this.defaultAmount).toFixed(2)
            this.$refs.mymodal.openModal()
            this.$nextTick(()=>{
                this.$refs.amtfield.focus()
            });
        },
        Save(){
            if(this.inSave){return}
            
            
            
            //see if this is allowed
            if(!this.allowOverAvailableAmount){
                if(Number(this.amount)> Number(this.availableAmount)){
                    this.responseMessage="Please enter an amount less than or equal to $"  + Number(this.availableAmount).toFixed(2)
                    return
                }
            }
            //if single pay, must be at least the def amt
            if(this.singlePay){
                if(Number(this.amount)< Number(this.defaultAmount)){
                    this.responseMessage="Please enter an amount at least equal to $"  + Number(this.defaultAmount).toFixed(2)
                    return
                }
            }
            if(this.name=='Card' && this.numberDataLabel!='' && this.numberData.length!=4){
                this.responseMessage="Please enter the last 4 digits of the card number"  
                    return
            }

            this.inSave=true

            var change=0
            if(Number(this.amount) > Number(this.defaultAmount)){ change=Number(this.defaultAmount)-Number(this.amount)}
            



            this.$emit('save',this.name, this.amount,this.numberData, change)
            this.$refs.mymodal.closeModal()
        },
        textIsSelected(){
            console.log(this.$refs.amtfield.value.length + ' ' + this.$refs.amtfield.selectionEnd )
            return this.$refs.amtfield.selectionStart == 0 && this.$refs.amtfield.selectionEnd == this.$refs.amtfield.value.length;
        },
    }
}
</script>

<style>
.addbtn{
    border: none;
      border-radius:2px;
      width:40px;
      height:40px;
      padding:0px;
      font-size: medium;
      margin:10px;
      text-decoration: none;
      background-color:var( --button-color);
      color:var(--light-text-color);

}
.addbtndiv{
    margin-left:170px;
}
</style>





























