<template>
    <div >
        <smallbutton @click="Add()" toplabel="Add New Item" :class="buttonclass"/>

        <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="myform">
                <label class="titlelabel">Add New Item</label>
                <br>
                <br>
                <br>
                <div class="entry"  >
                    <div class="entryTextDiv">
                        <label class="entryLabel bold" for="">1. Select Location</label>
                    </div>
                    <div class="entryTextDiv">
                    <select name="" id="" class='entrySelect' required v-model="newItem.locationId" @change="selectLocation()" >
                        <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                            {{loc.name}}
                        </option>
                    </select>
                    </div>
                </div>

                
                <hr>

                <div class="horizFlex">
                    <label class="lookuplabel bold">2. Lookup Student</label>
                    <input ref="studentLookup" class="lookupinput" inputtype="text" v-model="studentLookupString"   />
                    <smallbutton toplabel="Lookup" @click="LookupStudent" buttonclass="buttonprimary smallbutton"/>
                    
                </div>
                <br>
                <div class="entry" >
                    <div class="entryTextDiv">
                        <label class="entryLabel " for="">Selected Student</label>
                    </div>
                    <div class="entryTextDiv">
                        <label class="entryLabel">{{newItem.studentName }}</label>
                    </div>
                </div>
                
                
                <hr>
                

                <div class="entry" >
                    <div class="entryTextDiv">
                        <label class="entryLabel bold" for="">3. Select Product(s)</label>
                    </div>
                    <div class="entryTextDiv">
                    
                    </div>
                </div>

   
                
                <div class="entry" >
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Product Group</label>
                    </div>
                    <div class="entryTextDiv">
                    <select name="" id="" class='entrySelect' v-model="selectedProductGroupId" @change="selectGroup()"  >
                        <option v-for="s in productGroupList" :key="s.id" :value="s.id" >
                            {{s.name}}
                        </option>
                    </select>
                    </div>
                </div>
                
                <div class="vertFlex">
                    <div v-for="p in productList" :key="p.id" :value="p.id" class="productDiv" >
                        <input class="checkbox" type="checkbox" v-model="p.selected" >
                        <p class="checklabel">{{p.name}}</p>
                        <!-- <input class="" type="text" v-model="p.quantity" > -->
                    </div>
                </div>
                <hr>
                <div class="entry"  >
                    <div class="entryTextDiv">
                        <label class="entryLabel bold" for="">4. Set Due Date</label>
                    </div>
                    <div class="entryTextDiv">
                    <input type="date" v-model="newItem.dueDate" class="entrySelect">
                    </div>
                </div>


                <smallbutton  @click="save(true)" toplabel="Save and Add Another" buttonclass="buttonprimary extrawidebutton"/>
                <smallbutton  @click="save(false)" toplabel="Save and Close" buttonclass="buttonprimary extrawidebutton"/>
                <smallbutton  @click="cancel()" toplabel="Cancel" buttonclass="buttonprimary button"/>
                <div>
                    <label class="responsemessage">{{ responseMessage }}</label>
                </div>
                
            </form>
        </template>
        </actionmodal>

      </div>
  </template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
   import datamethods from '../../services/datamethods.js'
   import smallbutton from '../controls/button.vue'
   import actionmodal from '../controls/actionmodal.vue'
   import format from '../../format.js'
  export default {
      name:"addlibrary",
      mixins:[datamethods,format],
      props:{
        buttonclass:{
                type:String,
                default:'button buttonprimary'
            }
      },
      components:{
        smallbutton,
        actionmodal
      },
      data() {
          return {
             newItem:{
                locationId:-1,
                studentId:-1,
                studentName:null,
                employeeId:-1,
                libraryItemStatusId:-1,
                


             },
             studentLookupString:null,
             productGroupList:[],
             selectedProductGroupId:-1,
             selectedStudent:{},
             productList:[],
             responseMessage:null
          };
      },
      methods:{
        Add(){
            
            this.studentLookupString=null
           
            this.newItem={
                locationId:this.libraryFilter.locationId,
                studentId:-1,
                studentName:null,
                employeeId:this.loggedInUser.user_personId,
                libraryItemStatusId:-1,
                

            }
            this.productList=[]
            this.selectLocation()
            this.$refs.mymodal.openModal()
        },
        async selectLocation(){
            this.GetGroups()
        },
        async selectGroup(){
            this.GetProducts()
        },
        async LookupStudent(){
            this.responseMessage=null
            let req = {
                   person:{
                    StudentID:this.studentLookupString
                   }
             }
             this.$store.dispatch('beforeAPI');
             var response = await this.callAPI(req,'lookupstudent')
 
             if(response.Successful){
                if(response.ValueList.length==0){
                    this.responseMessage = 'No student found'
                    this.newItem.studentId=-1
                    this.newItem.studentName=null
                }else{
                    
                    this.newItem.studentId=response.ValueList[0].ID
                 this.newItem.studentName=response.ValueList[0].Name
                }
                 

                 
             }else{
                 this.responseMessage = response.Message
             }
             this.pleaseWait=false;
        },
        async save(addanother=false){
            this.responseMessage=null
            if(this.newItem.studentId==-1){
                this.responseMessage="Please lookup a student"
                return
            }
            if(this.newItem.dueDate==null){
                this.responseMessage="Please enter a due date"
                return
            }
            var today = this.$options.filters.formatDateForInputToday()
            if(this.newItem.dueDate<today){
                this.responseMessage="Please enter a due date after today"
                return
            }
            if(this.productSelected==false){
                this.responseMessage="Please select at least one product"
                return
            }
            let req = {
                   LibraryItem:this.newItem,
                   productList:this.productList
             }
             this.$store.dispatch('beforeAPI');
             var response = await this.callAPI(req,'addlibraryitem')
 
             if(response.Successful){
                 if(addanother){
                    this.newItem.studentId=-1
                    this.newItem.studentName=null
                    this.studentLookupString=null
                    this.$refs.studentLookup.focus()
                 }else{
                    this.cancel()
                 }
             }else{
                 this.responseMessage = response.Message
             }
             this.pleaseWait=false;
        },
        async cancel(){
            this.$emit('refresh')
            this.$refs.mymodal.closeModal()
        },
        async GetGroups(){
             let req = {
                   locationId:this.newItem.locationId
             }
             this.$store.dispatch('beforeAPI');
             var response = await this.callAPI(req,'productgrouplibrary')
 
             if(response.Successful){
                 this.productGroupList=response.ProductGroupList
                 this.selectedProductGroupId=-1
             }else{
                 this.responseMessage = response.Message
             }
             this.pleaseWait=false;
         },
         async GetProducts(){
            let req = {
                locationId:this.newItem.locationId,
                productGroupId:this.selectedProductGroupId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'products_bygroup')
            //handle response
            if(response.Successful){
                 this.productList=response.productList
              
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
         }
  
      },
      computed:{
        ...mapFields([
     
            'pleaseWait',
            'pleaseWaitText',
            'libraryFilter',
            'locationList',
            'loggedInUser',


        ]),
        productSelected(){
            var sel =false
            this.productList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let p=arr[index]
                        if (p.selected){sel=true}
                    } 
            return sel
        }
      },
      mounted(){
        

      }
  }
  </script>
  
  <style  >
  .extrawidebutton{

border: none;
border-radius:5px;
width:260px;
height:50px;
padding:0px;
font-size: medium;
margin:10px;
text-decoration: none;
color:var(--light-text-color);
}
.lookupinput{
    height:25px;
}
.productDiv{
    display:grid;
    grid-template-columns: 60px auto;
    text-align:left;
}
.checkbox{
    height:20px;
    margin:15px;
}
.smallbutton{
    height:35px;
    width:65px;
    color:var(--light-text-color);
    border:none;
    border-radius: 3px;
    font-size: inherit;
    margin-left:10px;
}
.lookuplabel{
    margin: 10px 10px 0px 5px;
}
  </style>
  