<template>
    <div class="pmtdiv">
        
                <form ref="balform">
                    
                  
                    <div class="filter horizFlex wrap " v-on:keyup.enter="gettickets" >
                        <p class="filterlabel">Lookup</p>
                        <input class="filterfield" type="date" v-model="filter.date1"/>
                        <p class="filterlabel">to</p>
                        <input class="filterfield" type="date" v-model="filter.date2"/>
                        
                        <select name="" id="" class='filterselect' v-model="filter.locationId"  @change="selectLocation()">
                            <option v-for="loc in ocLocationList" :key="loc.id" :value="loc.id" >
                                {{loc.name}}
                            </option>
                        </select>
                        <select name="" id="" class='filterselect' v-model="filter.terminalId" >
                            <option v-for="loc in terminalList" :key="loc.id" :value="loc.id" >
                                {{loc.name}}
                            </option>
                        </select>
                        <input class="filterfield"  v-model="filter.lookupString" placeholder="Guest Name"/>
                        <smallbutton toplabel="Go" @click="gettickets" buttonclass="buttonprimary narrowbutton"/>
                        <smallbutton toplabel="Reset" buttonclass="buttonprimary narrowbutton" @click="resetfilter"/>
                    </div>
                    
                  

                    

                    <div>
                        <label class="responsemessage">{{ responseMessage }}</label>
                    </div>
                       
                    <div class="ticketheader" >
                        <linkbutton class="ticketheaderlabel" @click="setsort('date')" toplabel="Time"/>
                        <linkbutton class="ticketheaderlabel" @click="setsort('guest')" toplabel="Guest"/>
                        <linkbutton class="ticketheaderlabel" @click="setsort('product')" toplabel="Product"/>
                        <linkbutton class="ticketheaderlabel" @click="setsort('employee')" toplabel="Employee"/>
                        <linkbutton class="ticketheaderlabel" @click="setsort('total')" toplabel="Total"/>
                        <linkbutton class="ticketheaderlabel" @click="setsort('payment')" toplabel="Payments"/>
                        <linkbutton class="ticketheaderlabel" @click="setsort('status')" toplabel="Status"/>
                        <linkbutton class="ticketheaderlabel"  toplabel=""/>
                       
                    </div>
                    <div class="vertScroll">
                        <div  v-for="t in sortedTicketList" :key="t.id" :value="t.id" >
                            <div class="ticketrecord">
                                <label class="ticketfield" for>{{toDate(t.endDateTime)}}</label>
                                <label class=" ticketfield " for>{{t.guestName}}</label>
                                <label class=" ticketfield  leftjustify" for>{{t.productServiceName}}</label>
                                <label class=" ticketfield  leftjustify" for>{{t.employeeName}}</label>
                                <label class=" ticketfield  leftjustify" for>$ {{t.total}}</label>
                                <label class=" ticketfield  leftjustify" for>{{t.ticketPaymentString}}</label>
                                <label class=" ticketfield  leftjustify" for>{{t.statusName}}</label>
                                <div class=" ticketfield horizFlex">
                                    <ticketdetail :ticketId="t.id" class="margin10"/>
                                    <linkbutton class="margin10" toplabel="Void Ticket" @click="checkVoid(t)" v-if="isvoidable(t)"/>
                                    
                                     <linkbutton  class="margin10" toplabel="Receipt" @click="getReceiptData(t.id)"/>
                                     <updatepayment :ticketId="t.id" class="margin10" v-if="t.statusName!='Voided'" @refresh="gettickets"/>
                                </div>
                                
                            </div>
                            
                        </div> 
                    </div>
                    <confirm :isAlert="trueParam" ref="confirmvoid"  class="margin10" :showButton="falseParam" :body="getRemoveString(tickettovoid.guestName)" title="Void Ticket" @yes="process_terminaltest(tickettovoid)" />
                    
                    <!-- <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div> -->
                    <securityoverride ref="override"  @overridesuccessful="overrideSuccessful"/>

                    <div hidden>
                        <div  class="receiptclass" id="receiptdatadiv" v-if="receiptData!=null" >
                        <label>{{receiptData}}</label>
                        </div>
                        <button ref="printthereceipt" class="btn actionbutton narrowbutton"  v-print="'#receiptdatadiv'" v-if="receiptData!=null" type="button">Print Receipt</button>
                    </div>
                    <messagebox :body="messageboxbody" :title="messageboxtitle" ref="messagebox" />
                </form>
                
                
    </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import formentry from '../controls/entry.vue'
    import datamethods from '../../services/datamethods.js'
    import confirm from '../actions/confirm.vue'
    import securityoverride from '../actions/securityoverride.vue'
    import securityhelper from '../../services/securityhelper.js'
    import ticketdetail from './ticketdetail.vue'
    import print from 'vue3-print-nb'
    import updatepayment from './updatepayment.vue'
    import messagebox from '../actions/messagebox.vue'
    export default {
        name:'transactions',
        components:{
            smallbutton,
            formentry,
            linkbutton,
            confirm,
            securityoverride,
            ticketdetail,
            updatepayment,
            messagebox
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:true
            },
            name:{
                type:String,
                default:"Today's Tickets"
            },
            defaultAmount:{
                type:Number,
            },
            numberDataLabel:{
                type:String,
                default:''
            },
            buttonClass:{
                type:String,
                default:'button buttonprimary bottom10'
            }
            
        },
        mixins:[format, datamethods,securityhelper],
        directives:{
            print
        },
        data(){
            return{
                ticketList:[],
                ticketId:0,
                trueParam:true, 
                terminalInfo:null,
                savedTicket:{},
                savedTicketId:0,
                savedEndDateTime:null,

                receiptData:null,
                filter:{
                    locationId:-1,
                    terminalId:1,
                    date1:null,
                    date2:null,
                    lookupString:null
                },
                maxTerminalId:1,
                sortBy:'default',
                sortaccending:true,
                messageboxbody:null,
                messageboxtitle:null,
                tickettovoid:{
                    ID:0,
                    GuestName:null,
                    endDateTime:null
                }
            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait',
                'location',
                'terminalId',
                'ocLocationList',
                'terminalList',
                'loggedInUser'
     
            ]),
            integratedCards(){
                if(window.INTEGRATED_CARDS==undefined){return false}
                else{return window.INTEGRATED_CARDS}
            },
            allowVoidPreviousCheck(){
                if(window.ALLOW_VOIDPREVIOUS_CHECK==undefined){return false}
                else{return window.ALLOW_VOIDPREVIOUS_CHECK}
            },
            allowVoidPreviousAR(){
                if(window.ALLOW_VOIDPREVIOUS_AR==undefined){return false}
                else{return window.ALLOW_VOIDPREVIOUS_AR}
            },
            allowVoidPreviousAccount(){
                if(window.ALLOW_VOIDPREVIOUS_ACCOUNT==undefined){return false}
                else{return window.ALLOW_VOIDPREVIOUS_ACCOUNT}
            },
            allowVoidPreviousGC(){
                if(window.ALLOW_VOIDPREVIOUS_GC==undefined){return false}
                else{return window.ALLOW_VOIDPREVIOUS_GC}
            },
            allowVoidPreviousNonIntegratedCard(){
                if(window.ALLOW_VOIDPREVIOUS_NONINTEGRATEDCARD==undefined){return false}
                else{return window.ALLOW_VOIDPREVIOUS_NONINTEGRATEDCARD}
            },

            sortedTicketList(){
                if(this.sortBy=='default'){return this.ticketList}

                var sort1 = this.sortaccending ? -1:1
                var sort2 = this.sortaccending ? 1:-1

                console.log('sort ' + this.sortBy)

                switch (this.sortBy) {
                    case 'date':
                        function compare(a,b){
                            if (a.endDateTime < b.endDateTime)
                                return sort1
                            if(a.endDateTime > b.endDateTime)
                                return sort2
                            return 0
                        }
                        return this.ticketList.sort(compare)
                        break;
                    case 'guest':
                        function compareb(a,b){
                            if (a.guestName.toLowerCase() < b.guestName.toLowerCase())
                                return sort1
                            if(a.guestName.toLowerCase() > b.guestName.toLowerCase())
                                return sort2
                            return 0
                        }
                        return this.ticketList.sort(compareb)
                        break;
                    case 'product':
                        function comparec(a,b){
                            if (a.productServiceName < b.productServiceName)
                                return sort1
                            if(a.productServiceName > b.productServiceName)
                                return sort2
                            return 0
                        }
                        return this.ticketList.sort(comparec)
                        break;    
                    case 'employee':
                        function compared(a,b){
                            if (a.employeeName.toLowerCase() < b.employeeName.toLowerCase())
                                return sort1
                            if(a.employeeName.toLowerCase() > b.employeeName.toLowerCase())
                                return sort2
                            return 0
                        }
                        return this.ticketList.sort(compared)
                        break;   
                    case 'total':
                        function comparee(a,b){
                            if (a.total < b.total)
                                return sort1
                            if(a.total > b.total)
                                return sort2
                            return 0
                        }
                        return this.ticketList.sort(comparee)
                        break;
                    case 'payment':
                        function comparex(a,b){
                            if (a.ticketPaymentString < b.ticketPaymentString)
                                return sort1
                            if(a.ticketPaymentString > b.ticketPaymentString)
                                return sort2
                            return 0
                        }
                        return this.ticketList.sort(comparex)
                        break;
                    case 'status':
                        function comparef(a,b){
                            if (a.statusName < b.statusName)
                                return sort1
                            if(a.statusName > b.statusName)
                                return sort2
                            return 0
                        }
                        return this.ticketList.sort(comparef)
                        break;
                    
                    default:
                        return this.ticketList
                        break;
                } 
            }
        },
        mounted(){
            this.getLocations()
            this.resetfilter()
            this.gettickets()
        },
        methods:{
            formatpayment(pmt){
                if(pmt==null){return null}
                var acctlabel=window.ACCOUNT_LABEL
                var pmtout = pmt.replaceAll('Declining Balance', acctlabel)
                return pmtout
            },
            showmessage(title, body){
                this.messageboxbody=body
                this.messageboxtitle=title
                this.$refs.messagebox.Show()
            },
            setsort(s){
                if(s==this.sortBy){this.sortaccending=!this.sortaccending}
                else{
                    this.sortBy=s
                }
            },
            isvoidable(t){
                return t.statusName!='Voided' && t.total >0
            },
            async getReceiptData(ticketid){
                let req = {
                    ticket:{
                        id:ticketid
                    }
                }  
                    //call api
                    this.$store.dispatch('beforeAPI');
                    var response = await this.callAPI(req,'ticketreceipt')
                    //handle response
                
                    if(response.Successful){
                        this.receiptData=response.StringResponse
                        this.pleaseWait=false;
                        //this.receipt() //print!
                    
                        this.$nextTick(() => {
                            console.log('print!')
                            this.$refs.printthereceipt.click()
                        })
                    
                    }else{
                        this.responseMessage = response.Message
                    }
                    this.pleaseWait=false;
            },
            toDate(dt){
                return this.$options.filters.formatDateTime(dt);
            },
            getRemoveString(name){
                return 'Do you want to void the ticket for ' + name + '?' + '     DO NOT VOID if you are just changing the payment type. Click Update Payments instead.'
            },
            async Remove(ID){
                this.responseMessage=null
                console.log('incancel')
                let req = {
                    ticket:{
                        id:ID
                    }
                }
                var response = await this.callAPI(req,'cancelticket')
                if(response.Successful){
                    this.$emit('removeticket', ID)
                    this.gettickets()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            checkprocess_terminaltest(t){
                this.savedTicketId=t.id
                this.savedEndDateTime=i.endDateTime
                this.savedTicket = t
                //stash this so we can go to it after it works
                if(this.canAccess_allowOverride('POS','Void Transaction')){
                    //console.log('already has access')
                    this.process_terminaltest(t)
                }

            },
            overrideSuccessful(){
                //console.log('override!!! call success function')
                this.process_terminaltest(this.savedTicket)
            },

            checkVoid(t){
               
              var pmttype = t.ticketPaymentString
            
              //if today, go ahead
              var today = this.$options.filters.formatDateForInputToday()
              var ticketdate = this.$options.filters.formatDateForInput(t.endDateTime)
              if(today==ticketdate){
                //this.process_terminaltest(t.ID, t.endDateTime)
                this.tickettovoid=t
                this.$refs.confirmvoid.Show()
                return
              }

              //if ecommerce go ahead
             
              if(t.Source=='ECommerce'){
                //this.process_terminaltest(t.ID, t.endDateTime)
                this.tickettovoid=t
                this.$refs.confirmvoid.Show()
                return
              }

              if(pmttype.includes('Cash')){
                this.showmessage('Void Ticket Not Allowed', 'You cannot void a Cash ticket from a previous day.  You need to start a new Return Ticket through the POS.')
                return
              }else if(pmttype.includes('Check') && !this.allowVoidPreviousCheck){
                this.showmessage('Void Ticket Not Allowed', 'You cannot void a Check ticket from a previous day.  You need to start a new Return Ticket through the POS.')
                return
              }else if(pmttype.includes('AR') && !this.allowVoidPreviousAR){
                this.showmessage('Void Ticket Not Allowed', 'You cannot void an AR ticket from a previous day.  You need to start a new Return Ticket through the POS.')
                return
              }else if((pmttype.includes('Account') || pmttype.includes('Declining Balance') ) && !this.allowVoidPreviousAccount){
                this.showmessage('Void Ticket Not Allowed', 'You cannot void an Account ticket from a previous day.  You need to start a new Return Ticket through the POS.')
                return
              }else if(pmttype.includes('GiftCard') && !this.allowVoidPreviousGC){
                this.showmessage('Void Ticket Not Allowed', 'You cannot void a Gift Card ticket from a previous day.  You need to start a new Return Ticket through the POS.')
                return
              }else if(pmttype.includes('Card') && !this.allowVoidPreviousNonIntegratedCard && !this.integratedCards){
                this.showmessage('Void Ticket Not Allowed', 'You cannot void a Credit Card ticket from a previous day.  You need to start a new Return Ticket through the POS.')
                return
              }else if(pmttype.includes('Card') && this.integratedCards){
                this.showmessage('Void Ticket Not Allowed', 'You cannot void a Credit Card ticket from a previous day.  You need to start a new Return Ticket through the POS.')
                return
              }

              //if we made it this far, we can go ahead
              //this.process_terminaltest(t.ID, t.endDateTime)
              this.tickettovoid=t
              this.$refs.confirmvoid.Show()
            },

            async process_terminaltest(t){
                var ID =t.id
                var endDateTime=t.endDateTime


                //if this is ecommerce, then call the other void
                if(t.Source=='ECommerce'){
                    this.voidecommerce(t)
                    return
                }

                //if we aren't integrated, then skip to the after
                if(this.integratedCards==false){
                    this.voidticket(ID); 
                    return
                }
                

                let req = {
                    ticket:{
                        id:ID,
                    },
                    isVoid:true
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'terminal')

                //handle response
                if(response.Successful){
                this.terminalInfo=response

                //if no payment then move on
                if(this.terminalInfo.payment==null){
                    console.log('no pmt')
                    this.voidticket(ID); //do the final processing
                    return;
                }
                else if (this.terminalInfo.payment.transactionAmount==null){
                    //console.log('pmt amt 0')
                    this.voidticket(ID); //do the final processing
                    return;
                }

                //call 
                let req2=this.terminalInfo.payment
                let headers = this.terminalInfo.headers
                //headers
                for(let i=0;i < headers.length;i++){
                    this.AddHeaderToTerminalAPI(headers[i].key, headers[i].value)
                }
                
                var response2 = await this.callAPI_Terminal(req2,this.terminalInfo.endpointString)
                //console.log(response2)
                this.completeTerminal(response2, ID)
                
                }else{
                this.responseMessage = response.Message
                }
                this.pleaseWait=false;
               

                
            },
            async completeTerminal(response, ID){
                let req = {
                    ticket:{id:ID},
                    response:response,
                    isVoid:true
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'terminalcomplete')

                //handle response
                if(response.Successful){
                
                    this.voidticket(ID) //finish!
                }else{
                    this.responseMessage = response.Message
                    this.$emit('refreshticketrebuild',this.responseMessage)
                    
                }
            },
            async voidticket(ID){
                this.responseMessage=null
                console.log('invoid')
                let req = {
                    ticket:{
                        id:ID
                    }
                }
                var response = await this.callAPI(req,'voidticket')
                if(response.Successful){
                    //this.$emit('removeticket', ID)
                    this.gettickets()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            async voidecommerce(t){
                this.responseMessage=null
                let req = {
                    ID:t.id
                    
                }
                var response = await this.callAPI(req,'voidticket_ecommerce')
                if(response.Successful){
                    this.gettickets()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            resetfilter(){

                this.filter.date1=this.$options.filters.formatDateForInputToday()
                this.filter.date2=this.$options.filters.formatDateForInputToday()
                this.filter.locationId=this.loggedInUser.LocationId
                this.filter.terminalId=-1
                this.filter.lookupString=null

            },
            async getLocations(){
          
                //call api
                this.$store.dispatch('beforeAPI');
                this.responseMessage=null
                var response = await this.callAPI(null,'locations')
                //handle response
            
                if(response.Successful){
                    
                    this.ocLocationList= response.locationList
                    this.filter.locationId=this.ocLocationList[0].id
                    this.selectLocation()
                    

                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
        },
        selectLocation(){
          console.log('selloc ' + this.location.id)
          var locid= this.filter.locationId
          var max=0;
          for(var i = 0; i<this.ocLocationList.length;i++){
            if (this.ocLocationList[i].id==locid){
              max=this.ocLocationList[i].maxTerminalId
              this.location.name=this.ocLocationList[i].name
            }
          }
          this.maxTerminalId=max
          this.getTerminalList()
          
        },
        getTerminalList(){
          
          //based on selected location
          var max = this.maxTerminalId
          if (max==0){
            this.terminalList=[0];
            this.terminalList[0]={id:-1, name:'All Terminals'}
            this.filter.terminalId=-1//this.terminalList[0].id
            


            return;
          }
          console.log('max ' + max)
          var tlist=[max];
          for(var i = 0; i<max;i++){
            tlist[i]={id:i+1, name:'Terminal ' + (i+1)}
            
          }
         this.terminalList=tlist

         var all = {id:-1,name:'All Terminals'}
        this.terminalList.unshift(all)

         if(this.terminalList.length>0){
          this.filter.terminalId=-1//terminalList[0].id
          
         }
        },


            ticketclass(g){
                let c = "ticketrecord"
     
                return c
            },
            selectTicket(id){
                console.log('sel ' + id)
                this.ticketId=id
            },
            start(){
                this.gettickets()
    
            },
            async gettickets(){
                let mythis = this
                this.inPayment=true
                
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(this.filter,'getfilteredtickets')
                //handle response
            
                if(response.Successful){
                    this.ticketList=response.ticketList
                    
                    this.ticketList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let p=arr[index]
                        p.ticketPaymentString= mythis.formatpayment(p.ticketPaymentString)
                    } 



                    if(this.ticketList.length>0){
                        this.selectTicket(this.ticketList[this.ticketList.length-1].id)
                    }
                    //this.sortBy='default'
                }else{
                this.responseMessage = response.Message
                }
                this.pleaseWait=false;
                        
            },
            receipt(drawer){
     
     var receiptText
     if(drawer){
       receiptText="drawer"
     }else{
       receiptText= this.receiptData
     }
     //test receipt
     var ePosDev = new epson.ePOSDevice();
     var printer = null
     var ipAddress = this.receiptIP;

     //if we don't have an ip...say so
     if(ipAddress==''){
       console.log("no receipt printer IP available. Please check URL and configuration")
       return
     }

     var port = this.receiptPort;
     ePosDev.connect(ipAddress, port, callback_connect);

     function callback_connect(data) {
       if(data == 'OK') {
         ePosDev.createDevice('local_printer', ePosDev.DEVICE_TYPE_PRINTER, {'crypto' : true, 'buffer' : false}, cbCreateDevice_printer);
       } else {
         console.log(data);
       }
     }
     function cbCreateDevice_printer(devobj, retcode) {
       if( retcode == 'OK' ) {
         printer = devobj;
         console.log(this.printer)
         executeAddedCode();
       } else {
         console.log(retcode);
       }
     }
     function executeAddedCode() {
       console.log('thinking about printing')
       if(receiptText=="drawer"){
         console.log('drawer please')
         printer.addSound(printer.PATTERN_A, 1, 1000);
       }else{
         console.log('receipt please')
         printer.addText(receiptText)
         printer.addCut(printer.CUT_FEED);
       }
       printer.send();
     }
   },
                
        }
    }
    
    </script>
    
    <style>
  
   
    .leftjustify{
        text-align: left;
    }
    .rightjustify{
        text-align: right;
    }
    .receiptclass{
  margin-top:120px;
  white-space: pre-wrap;
} 
.filter{
    padding-left:10px;
  }
.filterfield{
    height:30px;
    margin:5px;
    max-width:110px;
    font-size:inherit;
}
.filterselect{
    height:35px;
    margin:5px; 
    min-width: 125px;
    font-size:inherit;
}
.filterlabel{
    font-size:larger;
    margin:10px 0px 0px 0px;
    color:var(--text-color);
}
.narrowbutton{
  color:var(--light-text-color);
  border: none;
  border-radius:5px;
  min-width:50px;
  height:30px;
  padding:0px;
  font-size: medium;
  margin:5px;
  text-decoration: none;
  
  }
  .ticketrecord{
    font-size:smaller;
    text-align:left;
    background-color:var(--frame-color);
    margin:5px;
    border-radius: 2px;
    padding:0px 0px 0px 5px;
    display:grid;
    grid-template-columns: 1fr 2fr 3fr 2fr 1fr 1fr 1fr 5fr;
  }
  .ticketheader{
    text-align:left;
    font-size:smaller;
    margin:5px;
    border-radius: 2px;
    padding:0px 0px 0px 5px;
    display:grid;
    grid-template-columns: 1fr 2fr 3fr 2fr 1fr 1fr 1fr 5fr;
  }
  .vertScroll{
    overflow-x:hidden;
    overflow-y:scroll;
    width:100%;
    height:590px;
  }
  .ticketheaderlabel{
    font-weight:bold;
    min-width:100px;
    text-align: left;
    margin:10px 5px 5px 0px;
  }
  .ticketfield{
    min-width:100px;
    margin:10px 5px 5px 5px;
  }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    