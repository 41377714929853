<template>
    <div>
        <smallbutton toplabel="Generate Price Labels" @click="start()" v-if="showButton" :buttonclass="buttonclass"/>
        <linkbutton toplabel="Generate Price Labels" @click="start()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    <label class="titlelabel">Generate Price Labels</label>
                    <br/>
                    
                
                    <formentry   label="Count"  v-model="labelCount" :isrequired="trueParam" />
                    <formentry   label="Start at Column"  v-model="startCol" :isrequired="trueParam" />
                    <formentry   label="Start at Row"  v-model="startRow" :isrequired="trueParam" />

                    <div>
                        <label for="" class="required">{{requiredFieldLabel}}</label>
                    </div>
    
                    
                    <smallbutton  @click="save()" toplabel="Save" />
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    
                </form>
                
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../services/datamethods.js'
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodal from './actionmodal.vue'
    import formentry from '../controls/entry.vue'
    export default {
        name:'pricelabels',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton
        },
        props:{
            
            showButton:{
                type:Boolean,
                default:false
            },
            
             buttonclass:{
              type:String,
              default:"button buttonprimary"
            },
            onHand:{
                type:Number,
                default:0
            },
            productId:{
                type:Number,
            },
            locationId:{
                type:Number
            }
            
        },
        mixins:[datamethods,format],
        data(){
            return{
                
                responseMessage:'',
                pleaseWait:false,
                
                labelCount:0,
                startCol:1,
                startRow:1,
                requiredFieldLabel:'* required',
                trueParam:true,
                reportURL:null,
                imageBasePath:null
            }
        },
        computed:{
            ...mapFields([
    
    
            ]),
            
        },
        mounted(){
            var override 
            if(window.CONFIG_OVERRIDE!=undefined){
                override = window.CONFIG_OVERRIDE
            }

            this.AddConfigToAPI(override);

            this.getImageBasePath()
        },
        methods:{
            beforeAPI(){
                this.pleaseWait=true
                this.responseMessage=''
            },
            async getImageBasePath(){
                console.log('img')
                //call api
                this.beforeAPI();
                var response = await this.callAPI(null,'reportimagepath')
                //handle response
                if(response.Successful){
                    this.imageBasePath = window.VUE_CONFIG_ReportAPI + response.StringResponse
                }else{
                    this.message = response.Message
                }
                this.pleaseWait=false;
            },
          
            start(){
               this.labelCount = this.onHand //def to on hand
                
    
                this.$refs.mymodal.openModal()
            },
            async save(){
               let req = {
                    Report:{
                        Name:'ProductPriceLabel',
                        ID:0,
                        Parameters:[
                            {
                                SPParamName:'@SalonID',
                                CurrentValue:this.locationId
                            },
                            {
                                SPParamName:'@ProductID',
                                CurrentValue:this.productId
                            },
                            {
                                SPParamName:"@OnHandQuantity",
                                CurrentValue:this.labelCount
                            },
                            {
                                SPParamName:"@XCoordinate",
                                CurrentValue:this.startCol
                            },
                            {
                                SPParamName:"@YCoordinate",
                                CurrentValue:this.startRow
                            },
                            {
                                SPParamName:"@NumberOfLabelsAcross",
                                CurrentValue:"4"
                            }
                        ]
                    }
                }
                console.log(req)
                 this.$store.dispatch('beforeAPI');
                var response = await this.callAPI_Report(req,'generate')
                //handle response
                if(response.Successful){
                    let reportURL  = this.imageBasePath + response.Instances[0].ExportPath
                    this.$emit('pricelabels',reportURL)
                    this.$refs.mymodal.closeModal()
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
    
          
            
           
            
        }
    }
    </script>
    
    <style>
    
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    