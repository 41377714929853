<template>
    <div class="pmtdiv">
        <smallbutton toplabel="Review Transactions" @click="start()" v-if="showButton" :buttonclass="buttonClass"/>
        <linkbutton :toplabel="name" @click="start()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    <label class="titlelabel">Review Transactions</label>
                    <br/>

                    <select name="" id="" class='entrySelect' v-model="itemTypeId"  @change="selectType(0)" >
                        <option v-for="itype in itemTypes" :key="itype.ID" :value="itype.ID" >
                            {{itype.Name}}
                        </option>
                    </select>

                        <div :class="ticketclass(t)" v-for="t in ticketList" :key="t.id" :value="t.id" >
                            <div class="ticketgrid">
                                <label class="text subtitle leftjustified" for>{{t.guestName}}</label>
                                <label class="text subtitle leftjustified" for>{{t.name}}</label>
                                <label class="text subtitle rightjustified" for>{{t.NumberData}}</label>
                                <label class="text subtitle rightjustified" for>{{formatDate(t.dateEnded)}}</label>
                                <label class="text subtitle rightjustified" for>{{toCurrency(t.amount)}}</label>
                            </div>
                        </div>        
                    
                    <div class="totallabel">
                        <label class="">Total: {{ toCurrency(total) }}</label>
                    </div>

                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    
                </form>
                
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodal from '../actions/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import datamethods from '../../services/datamethods.js'
    import confirm from '../actions/confirm.vue'
    export default {
        name:'tickets',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton,
            confirm
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:true
            },
            businessDayId:{
                type:Number
            },
            terminalId:{
                type:Number
            },
            buttonClass:{
                type:String,
                default:'button buttonprimary'
            }
            
        },
        mixins:[format, datamethods],
        data(){
            return{
                ticketList:[],
                total:0,
                ticketId:0,
                trueParam:true,
                itemTypeId:-1,
                itemTypes:[],
                firstCall:true,
            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait',
                'businessDayId',
     
            ]),
            
        },
        methods:{
            async gettrans(){
        
                let req = {
                    businessDayId:this.businessDayId,
                    terminalId:this.terminalId,
                    paymentItemTypeId:this.itemTypeId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'sbdtransactions')
                //handle response
                if(response.Successful){
                    this.ticketList = response.transactionList
                    this.total = response.total
                    if(this.firstCall){
                        this.firstCall=false
                        this.$refs.mymodal.openModal()
                    }
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
            },
            async gettypes(){
        
                
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(null,'paymentitemtypes')
                //handle response
                if(response.Successful){
                    this.itemTypes = response.ValueList
                    //sel all
                    this.selectType(this.itemTypes[0].ID)
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
            },
            selectType(id){
                if(id!=0){
                    this.itemTypeId=id
                }
                
                this.gettrans()
            },
            ticketclass(g){
                let c = "guestdetails panel-inner"
                if(g.id==this.ticketId){
                    c=c+" color-highlight"
                }else{c=c+" color-inner"}
                return c
            },
            selectTicket(id){
                console.log('sel ' + id)
                this.ticketId=id
            },
            start(){
                this.firstCall=true
                this.gettypes()
                
            },
            toCurrency(value){
                return "$" + Number(value).toFixed(2);
            },
            formatDate(date){
                return this.$options.filters.formatDateTime(date)
            }
                
        }
    }
    
    </script>
    
    <style>
    .ticketgrid{
        display:grid;
        grid-template-columns: 2fr 1fr 1fr 2fr 1fr;
        border-bottom:1px solid gray;
    }
    .totallabel{
        margin:20px;
        text-align:right;
        font-size:large;
    }
    .entrySelect{
        font-size:large;
        margin:10px;
        height:30px;
        max-width:290px;
    }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    