<template>
    <div class="cashgrid">    
        <label class="leftjustified" for="">{{label}}</label>
        <button class="compare" >{{compareLabel}}</button>
        <label class="rightjustified" for="">$</label>
        <input type="text" class="amountText" v-if="allowTotalEntry" :value="total">
        <label class="rightjustified" v-if="!allowTotalEntry">{{total}}</label>
       <!--  <label class="rightjustified" for="">{{expected}}</label>
        <label class="rightjustified" for="">{{overShort}}</label> -->
    </div>
</template>

<script>
export default {
    name:'payentry',
    props:{
        label:{
            type:String,
            required:true,
            default:'Card'
        },
        total:{
            type:String,
            required:true,
            default:'0.00'
        },
        compareLabel:{
            type:String,
            required:true,
            default:'Compare Card'
        },
         expected:{
            type:String,
            required:true,
            default:'0.00'
        },
        overShort:{
            type:String,
            required:true,
            default:'0.00'
        },
        allowTotalEntry:{
            type:Boolean,
            default:true
        },
        
    },
    data() {
        return {
            
        };
    },
    
    computed:{
       

    },
    methods:{
        
    }
}
</script>

<style scoped>
.roll{
    width:60px;
}
.countText{
    width:60px;
}
.amountText{
    width:80px;
    margin-left: 5px;
}
.cashgrid{
    margin:5px;
    display:grid;
    grid-template-columns: 3fr 5fr 2fr 2fr;
}

</style>