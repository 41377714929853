<template>
    <div class="pmtdiv">
        <smallbutton :toplabel="name" @click="startAdd()" v-if="showButton" :buttonclass="buttonClass" :disabled="disabled"/>
        <linkbutton :toplabel="name" @click="startAdd()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    <label class="titlelabel">{{name}}</label>
                    <br/>
                    <div class="entry">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">AR Type</label>
                        </div>
                        <select name="" id="" class='entrySelect' v-model="subtypeId" >
                            <option v-for="st in subtypeList" :key="st.ID" :value="st.ID" >
                                {{st.Name}}
                            </option>
                        </select>
                    </div>
                    <formentry  isrequired="True" label="Amount $"  v-model="amount"  />
                    
                    <smallbutton  @click="Save()" toplabel="Save" />
                    <smallbutton  @click="close()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    
                </form>
                
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import datamethods from '../../services/datamethods.js'
    export default {
        name:'addpayment_ar',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:true
            },
            name:{
                type:String,
                default:'Add AR Payment'
            },
            defaultAmount:{
                type:Number,
            },
            numberDataLabel:{
                type:String,
                default:''
            },
            buttonClass:{
                type:String,
                default:'button buttonpay'
            },
            subtypeList:{
                type:Array
            },
        disabled:{
            type:Boolean,
            default:false
        }
            
        },
        mixins:[format,datamethods],
        data(){
            return{
                
                amount:0,
                subtypeId:0
                
            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait'
            ]),
            singlePay(){
                if(window.SINGLE_PAYMENT==undefined){return false}
                else{return window.SINGLE_PAYMENT}
            }
        },
        methods:{
            close(){
                this.responseMessage=null
                this.$refs.mymodal.closeModal()
            },
            startAdd(){
                this.subtypeId=0
                this.responseMessage=null
                this.amount = Number(this.defaultAmount).toFixed(2)
                this.$refs.mymodal.openModal()
                
            },
            Save(){
                if(this.subtypeId==0){
                    this.responseMessage="Please select an AR Type"  
                    return;
                }
                if(Number(this.defaultAmount)<Number(this.amount)){
                    this.responseMessage="Please enter an amount less than or equal to the remaining balance owed of $"  + this.defaultAmount
                    return;
                }
                if(Number(this.amount)==0){
                    this.responseMessage="Please enter an amount greater than $0"  
                    return;
                }
                //if single pay, must be at least the def amt
                if(this.singlePay){
                    if(Number(this.amount)< Number(this.defaultAmount)){
                        this.responseMessage="Please enter an amount at least equal to $"  + Number(this.defaultAmount).toFixed(2)
                        return
                    }
                }
                this.$emit('save',this.name, this.amount,this.subtypeId)
                this.$refs.mymodal.closeModal()
            },
            
        }
    }
    </script>
    
    <style>
 
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    