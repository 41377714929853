<template>
    <div >
          
      </div>
  </template>
  
  <script>
  export default {
      name:"updatelibrary",
      props:{
          
      },
      data() {
          return {
             
          };
      },
      methods:{
     
  
      },
      computed:{
          
      }
  }
  </script>
  
  <style  >
  
  </style>
  