import {ReportHTTP} from './repository'
export default{
    methods:{
        AddUserToAPI(user){
            console.log('add user to api ' + user.userId + ' ' + user.userKey)
            ReportHTTP.defaults.headers.post['adminuser']=user.userId + '|' + user.userKey
        },
        AddConfigToAPI(configoverride){
            console.log('add config to api ' + configoverride)
            ReportHTTP.defaults.headers.post['configoverride']=configoverride
        },
        getFailureResponse(message){
            return {
                'Successful':false,
                'Message':message
            }
        },
        async callAPI_Report(req,method){
            try{
                var response = await ReportHTTP.post(method,req)
                
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        }, 
    }
}