<template>
    <div >
        <smallbutton toplabel="Prior Changes" @click="start()" v-if="showButton" :buttonclass="buttonclass"/>
        
        <!--  MODAL -->
          <actionmodalwide ref="mymodal"> 
            <template v-slot:body>
                <form >
                    <label class="titlelabel"> </label>
                <div class="lookupdetail">
                    <div class="lookup">
                        <form  ref="lookupform" v-on:keyup.enter="lookup">
                            
                            <div class="vertFlexLeft">
                                <div class="lookupHeader">
                                    <label class="titlelabel bottom10">Lookup Prior Changes</label>
                                </div>
                                
                            <div >
                                <div class="entryTextDiv">
                                    <label class="entryLabel" for="">Location</label>
                                </div>
                                <select name="" id="" class='entrySelect' v-model="filter.locationId" required>
                                    <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                                        {{loc.name}}
                                    </option>
                                </select>
                            </div>
                            <formentrystacked  label="Product Code" v-model="filter.filterString" minlength="3"  />
                            <formentrystacked label="Action Date" v-model="filter.startDate" inputtype="date"/>
                            <div class="lookupHeader">
                               
                                <smallbutton @click="lookup()" toplabel="Go" buttonclass="button buttonprimary innerbutton"/>
                            </div>
                            
                            </div>
                        </form>
            
                        <div v-if="pleaseWait">
                            <label for="" class="pleasewaitlabel">{{pleaseWaitText}}</label>
                        </div> 
                        <div v-if="resultmessage!=null">
                            <label class="responsemessage">{{ resultmessage }}</label>
                        </div>
                        <div class="resultsdiv" v-if="lookupresults.length>0">
                            <div v-for="result in lookupresults" :key="result.inventoryActionSetId" :value="result.inventoryActionSetId" >
                                <lookupresult :topLeft="result.name"     @click="select(result)"
                                    :selected="result.inventoryActionSetId==selectedResultID" >
                                
                                </lookupresult>  
                            </div>
                        </div>
                    </div>
            
                    <div v-if="selectedResultID>0" class="pad10">
                        <div class="">
                            <label class="titlelabel">{{ selectedResultName }}</label>
                            <div class="urlclass" v-if="reportURL!=null">
                                <a ref="inventoryreportlink" :href="reportURL" target="_blank" >Click to view or print the Product Inventory Action Set Report </a>
                            </div>
                            <div class=" actionitemheader">
                                <div class="horizFlex">
                                    <p class="actionitem">Code</p>
                                    <p class="actionitem">Line</p>
                                    <p class="actionitem">Product</p>
                                    <p class="actionitem">Quantity Affected</p>
                                </div>
                                
                            </div>
                            <div v-for="pl in currentSet.inventoryActions" :key="pl.id" :value="pl.id" class=" actionitemdiv">
                                <div class="horizFlex">
                                    <p class="actionitem">{{ pl.productCode }}</p>
                                    <p class="actionitem">{{ pl.productLineName }}</p>
                                    <p class="actionitem">{{ pl.productName }}</p>
                                    <p class="actionitem">{{ pl.quantity }}</p>
                                </div>
                                
                                
                            </div>
                                    
                            
                        </div>

                    </div>
                    
                </div>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
                <smallbutton  @click="printReport" toplabel="Print Report" v-if="selectedResultID>0"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                </form>
            </template>
          </actionmodalwide>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import datamethods from '../../services/datamethods.js'
    import actionmodalwide from '../controls/actionmodalwide.vue'
     import lookupresult from '../controls/lookupresult.vue'
     import formentrystacked from '../controls/entry-stacked.vue'
    export default {
        name:'actionset',
        components:{
            smallbutton,
            actionmodalwide,
            lookupresult,
            formentrystacked
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:true
            },
            
         
            buttonclass:{
                type:String,
                default:'button buttonprimary bottom10'
            }
            
        },
        mixins:[format, datamethods],
        data(){
            return{
                trueParam:true, 
                filter:{
                    locationId:-1,
                    filterString:null,
                    startDate:null
                },
                lookupresults:[],
                currentSet:{},
                selectedResultID:0,
                selectedResultName:null,
                reportURL:null,
                responseMessage:null,
                resultmessage:null
            }
        },
        computed:{
            ...mapFields([
                'pleaseWait',
                'locationList',
                'loggedInUser',
                'reportImageBasePath'
               
            ]),
            
        },
        methods:{
            async printReport(print=true){
                let req = {
                  Report:{Name:'ProductInventoryActionSetReport',
                    Parameters:[{
                        SPParamName:'@ProductInventoryActionsetID',
                        CurrentValue:this.selectedResultID
                    }]
                  }
              }
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI_Report(req,'generate')
              //handle response
              if(response.Successful){
                if(response.Instances==null){
                    console.log('no instance')
                    return;
                }

                
                this.reportURL = this.reportImageBasePath + response.Instances[0].ExportPath
                if(print){
                    this.$nextTick(() => {
                        this.$refs.inventoryreportlink.click()
                    })
                }
                //this.$refs.mymodal.closeModal()
              }else{
                  this.responseMessage = response.Message
              }
              this.pleaseWait=false;  
            },
            toDate(dt){
                return this.$options.filters.formatTime(dt);
            },
            toCurrency(amt){
                return this.$options.filters.toCurrency(amt)
            },
            
         
            start(){
                this.selectedResultID=-1
                this.selectedResultName=null
                this.responseMessage=null
                this.resultmessage=null
                this.lookupresults=[]
                
                this.filter.startDate=null
                this.filter.filterString=null
                this.filter.locationId=this.loggedInUser.LocationId
                this.$refs.mymodal.openModal()
            },
            
            async lookup(){
                this.responseMessage=null
                this.resultmessage=null
                this.selectedResultID=-1
              this.selectedResultName=null
              
                this.lookupresults=[]
                let req = {
                    InventoryFilter:this.filter
                    
                }
                    //call api
                    this.$store.dispatch('beforeAPI');
                    var response = await this.callAPI(req,'actionsetlist')
                    //handle response
                
                    if(response.Successful){
                        this.lookupresults=response.InventoryActionSetList
                        if(this.lookupresults.length==0){
                          
                            this.resultmessage="No Inventory Changes Found"
                        }
                    }else{
                    this.responseMessage = response.Message
                    }
                    this.pleaseWait=false;   
             
          },
          select(result){
            this.reportURL=null
              this.selectedResultID=result.inventoryActionSetId
              this.selectedResultName=result.name
              this.getItem(result.inventoryActionSetId)
          },
          async getItem(id){
        
     
              let req = {
                  ID:id,
                  
              }
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI(req,'actionsetview')
              //handle response
              if(response.Successful){
                   this.currentSet =response.InventoryActionSet;
                   // this.printReport(id)
                 
              }else{
                  this.responseMessage = response.Message
              }
              this.pleaseWait=false;  
  
          },
          
        }
    }
    
    </script>
    
    <style>
   
  
    .leftjustify{
        text-align: left;
    }
    .rightjustify{
        text-align: right;
    }

.actionitem{
    width:150px;
    text-align: left;
    
    
}
.actionitemheader{
    padding-left:10px;
    margin:10px;
}
.actionitemdiv{
    border: 1px solid gray;
    padding-left:10px;
    margin:10px;
}

    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    